<template>
  <div
    :class="{
      animation: true,
      [`animation--${type}`]: true,
      'animation--grid': grid,
      'animation--done': done,
    }"
    :style="`--animation-delay: ${delay}`"
  >
    <slot />
  </div>
</template>

<script lang="ts" setup>
import { onMounted, ref } from 'vue'

defineProps({
  type: {
    type: String,
    default: 'fade-in-up',
  },
  delay: {
    type: String,
    default: '0s',
  },
  grid: {
    type: Boolean,
    default: false,
  },
})

const done = ref(false)

onMounted(() => {
  setTimeout(() => {
    done.value = true
  }, 750)
})
</script>

<style lang="scss" scoped>
@import '@boughtbymany/many-patterns/src/sass/framework/framework';

@keyframes fade {
  0% {
    opacity: 0;
  }
}

@keyframes fade-in-up {
  0% {
    opacity: 0;
    transform: translateY(1rem);
  }
}

@keyframes fade-in-right {
  0% {
    opacity: 0;
    transform: translateX(-1rem);
  }
}

@keyframes fade-in-left {
  0% {
    opacity: 0;
    transform: translateX(1rem);
  }
}

@keyframes boing {
  0% {
    opacity: 0;
    transform: scale(0.9);
  }

  100% {
    opacity: 1;
    transform: none;
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.animation {
  animation-delay: var(--animation-delay);
  animation-duration: 0.5s;
  animation-fill-mode: both;
  animation-timing-function: ease-in-out;

  &--grid {
    display: grid;
  }

  &--fade {
    animation-name: fade;
  }

  &--fade-in-up {
    animation-name: fade-in-up;
  }

  &--fade-in-right {
    animation-name: fade-in-right;
  }

  &--fade-in-left {
    animation-name: fade-in-left;
  }

  &--boing {
    animation-name: boing;
    animation-timing-function: cubic-bezier(0.84, 0.05, 0.46, 1.78);
  }

  &--done {
    animation: none;
  }

  &--spin {
    animation: spin 2.5s infinite linear;
  }
}
</style>

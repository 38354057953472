import { isVetUserMigrationEnabled } from '@/lib/opsToggle'

import Auth0Guard from '../guards/Auth0Guard'

const meta = {
  requiresAuth: true,
  role: 'PracticeManager',
}

const routes = [
  {
    path: '/hub',
    name: 'portal-home',
    component: () => import('../../views/Claims.vue'),
    meta,
    beforeEnter: Auth0Guard,
  },
  {
    path: '/hub/practice',
    name: 'practice',
    component: () => import('../../views/portal/Practice.vue'),
    meta,
    beforeEnter: Auth0Guard,
  },
  {
    path: '/hub/practices',
    name: 'practices',
    component: () => import('../../views/portal/Practices.vue'),
    meta,
    beforeEnter: Auth0Guard,
  },
  {
    path: '/hub/profile',
    name: 'portal-profile',
    component: () => {
      if (isVetUserMigrationEnabled()) {
        return import('../../views/VetUserProfile.vue')
      } else {
        return import('../../views/Profile.vue')
      }
    },
    meta,
    beforeEnter: Auth0Guard,
  },
  {
    path: '/hub/account-details',
    name: 'portal-account-details',
    component: () => import('../../views/portal/AccountDetails.vue'),
    meta,
    beforeEnter: Auth0Guard,
  },
  {
    path: '/hub/find-policy',
    name: 'find-policy',
    component: () => import('../../views/portal/FindPolicy.vue'),
    meta,
    beforeEnter: Auth0Guard,
  },
  {
    path: '/hub/policy-information',
    name: 'policy-information',
    component: () => import('../../views/portal/PolicyInformation.vue'),
    meta,
    beforeEnter: Auth0Guard,
  },
  {
    path: '/hub/claims/:caseId/losses/:lossId',
    name: 'portal-claim',
    component: () => import('../../views/Claim.vue'),
    meta,
    beforeEnter: Auth0Guard,
  },
  {
    path: '/hub/document-requests',
    name: 'portal-document-requests',
    component: () => import('../../views/portal/DocumentRequests.vue'),
    meta,
    beforeEnter: Auth0Guard,
  },
  {
    path: '/hub/new-claim-form/',
    name: 'new-claim-form',
    component: () => import('../../views/portal/NewClaimForm.vue'),
    meta,
    beforeEnter: Auth0Guard,
  },
  {
    path: '/hub/claims/new',
    name: 'new-claim',
    component: () => import('../../views/portal/claims/new/FindPolicy.vue'),
    meta,
    beforeEnter: Auth0Guard,
  },
  {
    path: '/hub/claims/new/select-pet',
    name: 'select-pet',
    component: () => import('../../views/portal/claims/new/SelectPet.vue'),
    meta,
    beforeEnter: Auth0Guard,
  },
  {
    path: '/hub/claims/new/existing-claims',
    name: 'existing-claims',
    component: () => import('../../views/portal/claims/new/ExistingClaims.vue'),
    meta,
    beforeEnter: Auth0Guard,
  },
  {
    path: '/hub/claims/new/claim-type',
    name: 'claim-type',
    component: () => import('../../views/portal/claims/new/Type.vue'),
    meta,
    beforeEnter: Auth0Guard,
  },
  {
    path: '/hub/claims/new/claim-details',
    name: 'claim-details',
    component: () => import('../../views/portal/claims/new/Details.vue'),
    meta,
    beforeEnter: Auth0Guard,
  },
  {
    path: '/hub/claims/new/review',
    name: 'review',
    component: () => import('../../views/portal/claims/new/Review.vue'),
    meta,
    beforeEnter: Auth0Guard,
  },
  {
    path: '/hub/claims/new/confirmation',
    name: 'confirmation',
    component: () => import('../../views/portal/claims/new/Confirmation.vue'),
    meta,
    beforeEnter: Auth0Guard,
  },
]

export default routes

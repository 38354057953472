import {
  CoverageBalance,
  PolicyCoverages,
} from '@/domain/application/application'
import {
  PolicyLimitsV2CoverageLimit,
  PolicyLimitsSubCoverage,
  PolicyLimitsCoverage,
  PolicyLimitsSubCoverageName,
} from '@/domain/pio/Policy'

export const allSubcoverages: {
  subcoverage: PolicyLimitsSubCoverageName
  name: string
}[] = [
  { subcoverage: 'behaviour_treatment', name: 'Behaviour Treatment' },
  { subcoverage: 'bilateral_conditions', name: 'Bilateral Conditions' },
  {
    subcoverage: 'complementary_treatment',
    name: 'Complementary Treatment',
  },
  {
    subcoverage: 'complications_from_routine_treatment',
    name: 'Complications from Routine Treatment',
  },
  {
    subcoverage: 'complications_of_pregnancy',
    name: 'Complications of Pregnancy',
  },
  { subcoverage: 'cruciate', name: 'Cruciate' },
  { subcoverage: 'dental_accident', name: 'Dental Accident' },
  { subcoverage: 'dental_illness', name: 'Dental Illness' },
  { subcoverage: 'mri_and_ct_scans', name: 'MRI and CT Scans' },
  { subcoverage: 'prescribed_food', name: 'Prescribed Food' },
]

export const allowedTrackableSubcoveragesNames: PolicyLimitsSubCoverageName[] =
  [
    'complementary_treatment',
    'prescribed_food',
    'dental_accident',
    'mri_and_ct_scans',
    'cruciate',
  ]

export const getCoverageBalance = (
  coverage: PolicyLimitsCoverage
): CoverageBalance => {
  return {
    claimed: coverage.amount_spent / 100,
    pending: coverage.amount_in_progress / 100,
    total: coverage.policy_limit / 100,
    remaining: (coverage.policy_limit - coverage.amount_spent) / 100,
  }
}

export const getSubCoverageBalance = (
  subCoverage: PolicyLimitsSubCoverage
): CoverageBalance => {
  return {
    claimed: subCoverage.amount_spent / 100,
    pending: subCoverage.amount_in_progress / 100,
    total: subCoverage.limit_amount ? subCoverage.limit_amount / 100 : 0,
    remaining: subCoverage.limit_amount
      ? (subCoverage.limit_amount - subCoverage.amount_spent) / 100
      : 0,
  }
}

export const getSubCoveragesBalances = (
  coverage: PolicyLimitsCoverage,
  subcoverages: PolicyLimitsSubCoverage['name'][]
) => {
  const subCoveragesToReturn: {
    balance: CoverageBalance
    subCoverage: PolicyLimitsSubCoverage
  }[] = []

  subcoverages.forEach((subcoverageName) => {
    const subCoverage = coverage.subcoverages.find(
      (subcoverage) => subcoverage.name === subcoverageName
    )
    if (subCoverage) {
      const subcoverageBalance = getSubCoverageBalance(subCoverage)

      if (subcoverageBalance.remaining > 0) {
        subCoveragesToReturn.push({
          balance: subcoverageBalance,
          subCoverage: subCoverage,
        })
      }
    }
  })

  return subCoveragesToReturn
}

const getCoverage = (
  coverageNames: string[],
  limits?: PolicyLimitsV2CoverageLimit
): PolicyLimitsCoverage[] => {
  if (!limits?.coverages) {
    return []
  }

  return limits?.coverages.filter((coverage) =>
    coverageNames.includes(coverage.coverage)
  )
}

export const getVetFeesCoverages = (limits?: PolicyLimitsV2CoverageLimit) => {
  const vetFeesCoverage = getCoverage(['vet_fees', 'vet-fees'], limits)[0]
  if (vetFeesCoverage) {
    return vetFeesCoverage
  }

  return null
}

export const getCoverages = (limits?: PolicyLimitsV2CoverageLimit) => {
  const allCoverages: PolicyCoverages[] = []
  const vetFeesCoverage = getVetFeesCoverages(limits)
  if (vetFeesCoverage) {
    const vetFeesCoverageStateObject = {
      balance: getCoverageBalance(vetFeesCoverage),
      coverage: vetFeesCoverage,
      name: 'vet_fees', // use the SGP name for ease and future proofing. Will also aid ease of selection in the UI.
      subCoverages: getSubCoveragesBalances(
        vetFeesCoverage,
        allowedTrackableSubcoveragesNames
      ),
    }

    allCoverages.push(vetFeesCoverageStateObject)
  }

  return allCoverages
}

import dayjs from 'dayjs'

import type {
  ClaimFormData,
  ClaimFormContinuationData,
  Claim,
} from '@/domain/pio/Claim'
import {
  GenericLoss,
  LossDocumentUnsaved,
  LossFlag,
  LossPayeeFormData,
  VetFeesLossData,
} from '@/domain/pio/Loss'
import { Relationship } from '@/domain/pio/Payee'
import { PolicyV2 } from '@/domain/pio/Policy'
import { AccountDetails } from '@/domain/vetPortal/AccountDetails'
import { ClaimForm } from '@/domain/vetPortal/ClaimForm'
import { Company } from '@/domain/vetPortal/Company'
import { decrypt } from '@/lib/crypto'
import { formatDate } from '@/lib/format'
import { createClaim, searchClaims } from '@/services/pio/claimService'
import { getPolicyV2 } from '@/services/pio/policyService'

const formatToPennies = (price: string) => {
  const priceFloat = parseFloat(price)

  return Math.round(priceFloat * 100)
}

//#endregion

//#region Create PIO claim from claim form data

export function createClaimPayloadWithForm({
  claimForm,
  petUuid,
  companyDetails,
  documents,
  paymentDetails,
  policy,
  paymentMethod,
  paymentType,
  continuationId,
  flags,
  note,
}: {
  claimForm: ClaimForm
  petUuid: string
  companyDetails: Record<string, any>
  documents: LossDocumentUnsaved[]
  paymentDetails: Record<string, any>
  policy: PolicyV2
  paymentMethod: string // TODO: CAN BE INFERRED AS BANK_TRANSFER IS THE ONLY OPTION
  paymentType: Relationship // TODO: this parameter is duplicated within the ClaimForm object too
  continuationId?: string
  flags?: LossFlag[]
  note?: string
}): ClaimFormData | ClaimFormContinuationData {
  const productLine = policy.product_line
  // TODO: In what scenario is this going to happen now? Is this a hangover from when policy info was cached on the Portal DB?
  if (!productLine) {
    throw new Error(`Unable to correctly identify policy`)
  }

  const pet = policy.pets.find((pet) => pet.uuid === petUuid)
  if (!pet) {
    throw new Error(`Pet not found on policy`)
  }

  const coverage = productLine === 'cat-dog' ? 'vet-fees' : 'vet_fees'

  const claimConditionItem = claimForm.condition

  const condition: {
    algoliaID: string
    group: string
    id: string
    section: string
    subgroup: string
  } = {
    algoliaID: claimConditionItem.algoliaID,
    group: claimConditionItem.conditionType,
    id: claimConditionItem.conditionId,
    section: claimConditionItem.conditionType,
    subgroup: claimConditionItem.conditionName,
  }

  // TODO: I think we can get rid of this... Needs confirming.
  if (claimConditionItem.conditionType === 'Custom') {
    condition.algoliaID = '154525001'
    condition.id = 'con_Pm872lV94n0gNbfKCW04xa'
  }

  const payee: LossPayeeFormData = {
    payee_type: paymentType,
    payment_method: 'MANUAL', // TODO: is it really always manual when the payee is a POLICYHOLDER?
  }

  if (paymentType === 'VET') {
    if (!companyDetails.vet_practice_list_id) {
      throw new Error(`Vet practice list id not provided `)
    }
    payee.payment_method = paymentMethod
    payee.account = {
      account_name: decrypt(paymentDetails.account_name),
      account_number: decrypt(paymentDetails.account_number),
      sort_code: decrypt(paymentDetails.sortcode),
    }
    payee.vet_practice_id = companyDetails.vet_practice_list_id
  }

  const lossTitle = `Vet Submitted claim - ${claimConditionItem.conditionName}`

  let additional_info = ''
  additional_info += note ? `${note}\n----------\n` : ''

  additional_info += `Treatment period: ${formatDate(
    claimForm.claimStartDate
  )} to ${formatDate(claimForm.claimEndDate)}\n`

  additional_info += claimConditionItem.conditionName
    ? `Condition: ${claimConditionItem.conditionName}\n`
    : ''

  additional_info += claimForm.referralPracticeName
    ? `Referring practice: ${claimForm.referralPracticeName}`
    : ''

  const dataObj = {
    accident: {
      cause_of_loss:
        claimConditionItem.accident === 'No' ? 'ILLNESS' : 'ACCIDENT',
      description: lossTitle,
    },
    additional_info,
  } as VetFeesLossData

  //Subcoverages
  const subcoverages: GenericLoss['subcoverages'] = claimForm.subcoverages.map(
    (subcoverage) => ({
      ...subcoverage,
      amount: formatToPennies((subcoverage.amount || 0).toString()),
    })
  )

  return {
    ...(continuationId && {
      continuation_id: continuationId,
    }),
    insured_entity_id: petUuid,
    losses: [
      {
        ...(flags && {
          flags,
        }),
        amount:
          (claimForm.claimAmount &&
            formatToPennies(claimForm.claimAmount.toString())) ||
          undefined, // Loss stores claim amount as pennies
        condition,
        coverage,
        subcoverages,
        date_of_loss: dayjs(claimForm.claimStartDate).format('YYYY-MM-DD'),
        treatment_end_date: dayjs(claimForm.claimEndDate).format('YYYY-MM-DD'),
        data: dataObj,
        documents,
        payee,
      },
    ],
    policy_sid: pet.policy_id,
    title: lossTitle,
    vet_internal_claim_id: claimForm.vetClaimRef,
  }
}

export const processClaimWithForm = async ({
  claimForm,
  policyId,
  petUuid,
  companyDetails,
  paymentDetails,
  continuationId,
  claimFormDocuments,
  flags,
  note,
}: {
  claimForm: ClaimForm
  policyId: string
  petUuid: string
  companyDetails: Company
  paymentDetails: AccountDetails
  continuationId?: string
  claimFormDocuments?: Array<LossDocumentUnsaved>
  flags?: LossFlag[]
  note?: string
}): Promise<Claim> => {
  let policy: PolicyV2
  try {
    policy = await getPolicyV2(policyId, {
      policy_at_date: dayjs(claimForm.claimStartDate).format('YYYY-MM-DD'),
    })
  } catch (error: any) {
    throw new Error('E2006: Unable to get policy for claim', { cause: error })
  }

  if (!claimFormDocuments?.length) {
    throw new Error('E2007: Unable to get claim documents')
  }

  const invoiceDocuments = claimFormDocuments.filter(
    (doc: LossDocumentUnsaved) => doc.type === 'INVOICE'
  )

  const medicalHistoryDocuments = claimFormDocuments.filter(
    (doc: LossDocumentUnsaved) => doc.type === 'MEDICAL_HISTORY'
  )

  if (invoiceDocuments.length === 0 || medicalHistoryDocuments.length === 0) {
    /**
     * The console.error has been adding to assist in debugging why document uploads sometimes fail.
     */
    // eslint-disable-next-line
    console.error(claimFormDocuments)
    throw new Error('E2007: Not all documents have been provided')
  }

  const payee = claimForm.payee.toLowerCase()
  let paymentType: Relationship = 'VET'

  if (payee === 'policy holder') {
    paymentType = 'POLICYHOLDER'
  } else if (payee === 'other') {
    paymentType = 'OTHER'
  }

  try {
    const claimFormData = createClaimPayloadWithForm({
      claimForm,
      petUuid,
      companyDetails,
      documents: claimFormDocuments,
      paymentDetails,
      policy,
      paymentMethod: 'BANK_TRANSFER', // TODO: The more I look at this, the more I think it's a redundant field for createClaimPayload and it should be a const inside the function instead for Vet Payee's
      paymentType,
      continuationId,
      flags,
      note,
    })

    const claim = await createClaim(claimFormData)
    return claim
  } catch (error: any) {
    throw new Error('E2005: Unable to create claim in PIO', { cause: error })
  }
}

//#endregion

//#region Existing claims

export async function getExistingClaims({
  policyId,
  insuredEntityId,
}: {
  policyId: string
  insuredEntityId: string
}) {
  if (!policyId) {
    return []
  }

  const { items: claims } = await searchClaims({
    search: policyId,
  })

  if (claims?.length) {
    const orderedClaims = claims
      .map((claim: Claim) => {
        claim.losses.sort(function (a: GenericLoss, b: GenericLoss) {
          return (
            new Date(a.date_of_loss).getTime() -
            new Date(b.date_of_loss).getTime()
          )
        })
        return claim
      })
      .filter((claim: Claim) => {
        return (
          !claim.insured_entity_deceased &&
          claim.insured_entity_id === insuredEntityId
        )
      })

    if (orderedClaims.length) {
      return orderedClaims
    } else {
      return []
    }
  } else {
    return []
  }
}

//#endregion

//#region Claim utils

export const isPayableToVet = (loss: GenericLoss) => {
  return loss.payee?.payee_type === 'VET'
}

// Get the amount paid as a string so it can be formatted.
// The payment details API will return an empty string or 0 if the amount is 0,
// but undefined if it has not been paid yet.
export const getPaid = (amount?: string) => {
  if (amount === '') {
    return '0'
  }

  return amount || ''
}

//#endregion

export default {
  createClaim,
  isPayableToVet,
  getPaid,
}

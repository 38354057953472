import { useAuth } from '@/store/auth'
import { useFeatureFlags } from '@/store/featureFlags'
import { useFnolStore } from '@/store/fnol'
import { usePolicyStore } from '@/store/policy'
import { useRouter } from '@/store/router'

export const clearAllStores = () => {
  useAuth().$reset()
  useFeatureFlags().$reset()
  usePolicyStore().reset() // $reset is not available for store's using `setup`
  useFnolStore().reset() // $reset is not available for store's using `setup`
  useRouter().$reset()
}

import { defineStore } from 'pinia'
import { computed, reactive, toRefs } from 'vue'
import { useRouter } from 'vue-router'

import { Claim } from '@/domain/pio/Claim'
import { PolicyV2 } from '@/domain/pio/Policy'
import { PetV2 } from '@/domain/pio/Policy'

// Define the steps in the FNOL process
export enum FnolStep {
  FIND_POLICY = 'find-policy',
  SELECT_PET = 'select-pet',
  EXISTING_CLAIMS = 'existing-claims',
  CLAIM_TYPE = 'claim-type',
  CLAIM_DETAILS = 'claim-details',
  REVIEW = 'review',
  CONFIRMATION = 'confirmation',
}

// Claim types
export enum ClaimType {
  ACCIDENT = 'accident',
  ILLNESS = 'illness',
}

// Data types for each form step
export interface FindPolicyData {
  searchType: string
  policyNumber?: string
  email?: string
  postcode: string
  postcodeMismatch: boolean
  treatmentStartDate: string
  treatmentEndDate: string
  activePolicies: PolicyV2[]
}

export interface SelectPetData {
  pet?: PetV2
  policy?: PolicyV2
}

export interface ExistingClaimsData {
  existingClaims?: Claim[]
  searchCompleted?: boolean
  hasDuplicates?: boolean
  claimForContinuation?: Claim
}

export interface ClaimTypeData {
  type?: ClaimType
}

export interface ClaimDetailsData {
  // TODO - Add claim details fields
  // Do we need to set up a separate interface for each claim type?
}

export interface ReviewData {
  agreedToTerms: boolean
}

export interface FnolState {
  steps: FnolStep[]
  currentStep: FnolStep
  findPolicy: FindPolicyData
  selectPet: SelectPetData
  existingClaims: ExistingClaimsData
  claimType: ClaimTypeData
  claimDetails: ClaimDetailsData
  review: ReviewData
}

export const routes = {
  'find-policy': '/hub/claims/new',
  'select-pet': '/hub/claims/new/select-pet',
  'existing-claims': '/hub/claims/new/existing-claims',
  'claim-type': '/hub/claims/new/claim-type',
  'claim-details': '/hub/claims/new/claim-details',
  review: '/hub/claims/new/review',
  confirmation: '/hub/claims/new/confirmation',
} as const

const defaultValues = {
  findPolicy: {
    searchType: 'policyNumber',
    policyNumber: '',
    email: '',
    postcode: '',
    postcodeMismatch: false,
    treatmentStartDate: '',
    treatmentEndDate: '',
    activePolicies: [],
  },
  selectPet: {},
  existingClaims: {
    existingClaims: [],
    searchCompleted: false,
    hasDuplicates: false,
    claimForContinuation: undefined,
  },
  claimType: {},
  claimDetails: {},
  review: { agreedToTerms: false },
}

export const useFnolStore = defineStore(
  'fnol',
  () => {
    const router = useRouter()

    // State
    const state = reactive<FnolState>({
      steps: [
        FnolStep.FIND_POLICY,
        FnolStep.SELECT_PET,
        FnolStep.EXISTING_CLAIMS,
        FnolStep.CLAIM_TYPE,
        FnolStep.CLAIM_DETAILS,
        FnolStep.REVIEW,
        FnolStep.CONFIRMATION,
      ],
      currentStep: FnolStep.FIND_POLICY,
      findPolicy: defaultValues.findPolicy,
      selectPet: defaultValues.selectPet,
      existingClaims: defaultValues.existingClaims,
      claimType: defaultValues.claimType,
      claimDetails: defaultValues.claimDetails,
      review: defaultValues.review,
    })

    // Getters
    const currentStepIndex = computed(() => {
      return state.steps.findIndex((step) => step === state.currentStep)
    })

    const canNavigateToStep = function (this: any, step: FnolStep) {
      const steps = state.steps
      const targetStepIndex = steps.findIndex((s) => s === step)

      if (targetStepIndex === -1) return false

      // Can navigate to next step if current step is completed
      const prevStep = steps[targetStepIndex - 1]
      if (!prevStep) return true

      return this.isStepCompleted(prevStep)
    }

    // Action to update find policy data
    const updateFindPolicyData = (data: Partial<FindPolicyData>) => {
      state.findPolicy = {
        ...state.findPolicy,
        ...data,
      }
    }

    // Action to update select pet data
    const updateSelectPetData = (data: Partial<SelectPetData>) => {
      state.selectPet = {
        ...state.selectPet,
        ...data,
      }
    }

    // Action to update existing claims data
    const updateExistingClaimsData = (data: Partial<ExistingClaimsData>) => {
      state.existingClaims = {
        ...state.existingClaims,
        ...data,
      }
    }

    // Action to update claim type data
    const updateClaimTypeData = (data: Partial<ClaimTypeData>) => {
      // Reset claim details if claim type changes
      if (data.type && data.type !== state.claimType.type) {
        state.claimDetails = defaultValues.claimDetails
      }

      state.claimType = {
        ...state.claimType,
        ...data,
      }
    }

    // Action to update claim details data
    const updateClaimDetailsData = (data: Partial<ClaimDetailsData>) => {
      state.claimDetails = {
        ...state.claimDetails,
        ...data,
      }
    }

    // Action to update review data
    const updateReviewData = (data: Partial<ReviewData>) => {
      state.review = {
        ...state.review,
        ...data,
      }
    }

    // Navigate to next step
    const goToNextStep = function (this: any) {
      const steps = state.steps
      const currentIndex = currentStepIndex.value

      if (currentIndex < steps.length - 1) {
        const nextStep = steps[currentIndex + 1]
        this.navigateToStep(nextStep)
      }
    }

    // Navigate to previous step
    const goToPreviousStep = function (this: any) {
      const steps = state.steps
      const currentIndex = currentStepIndex.value

      if (currentIndex > 0) {
        const prevStep = steps[currentIndex - 1]
        this.navigateToStep(prevStep)
      }
    }

    // Navigate to a specific step
    const navigateToStep = function (this: any, step: FnolStep) {
      if (this.canNavigateToStep(step)) {
        state.currentStep = step
        router.push(routes[step])
      }
    }

    // Helper to check if a step is completed
    // TODO - Implement this for each step by
    // working out what the store need sot look like for
    // this to be valid
    const isStepCompleted = (step: FnolStep) => {
      switch (step) {
        case FnolStep.FIND_POLICY:
          return true
        case FnolStep.SELECT_PET:
          return true
        case FnolStep.EXISTING_CLAIMS:
          return true
        case FnolStep.CLAIM_TYPE:
          return true
        case FnolStep.CLAIM_DETAILS:
          return true
        case FnolStep.REVIEW:
          return true
        case FnolStep.CONFIRMATION:
          return true
        default:
          return false
      }
    }

    // Reset the entire form
    const reset = () => {
      state.currentStep = FnolStep.FIND_POLICY
      state.findPolicy = defaultValues.findPolicy
      state.selectPet = defaultValues.selectPet
      state.existingClaims = defaultValues.existingClaims
      state.claimType = defaultValues.claimType
      state.claimDetails = defaultValues.claimDetails
      state.review = defaultValues.review
    }

    const resetAfterCurrentStep = () => {
      // Get the current step index
      const currentIndex = currentStepIndex.value

      // Reset all steps after the current step
      state.steps.slice(currentIndex + 1).forEach((step) => {
        switch (step) {
          case FnolStep.FIND_POLICY:
            state.findPolicy = defaultValues.findPolicy
            break
          case FnolStep.SELECT_PET:
            state.selectPet = defaultValues.selectPet
            break
          case FnolStep.EXISTING_CLAIMS:
            state.existingClaims = defaultValues.existingClaims
            break
          case FnolStep.CLAIM_TYPE:
            state.claimType = defaultValues.claimType
            break
          case FnolStep.CLAIM_DETAILS:
            state.claimDetails = defaultValues.claimDetails
            break
          case FnolStep.REVIEW:
            state.review = defaultValues.review
            break
        }
      })
    }

    // Redirect to the current step's route
    const redirectToCurrentStep = () => {
      const currentRoute = routes[state.currentStep]
      const currentPath = router.currentRoute.value.path

      // Get step for current path
      const currentPathStep = Object.entries(routes).find(
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        ([_, route]) => route === currentPath
      )?.[0] as FnolStep

      if (currentPathStep) {
        const currentPathIndex = state.steps.indexOf(currentPathStep)
        const allowedStepIndex = state.steps.indexOf(state.currentStep)

        // Only redirect if trying to access a step further than allowed
        if (currentPathIndex > allowedStepIndex) {
          router.push(currentRoute)
        } else {
          // Otherwise, update the current step to the current path
          state.currentStep = currentPathStep
        }
      }
    }

    return {
      ...toRefs(state),
      currentStepIndex,
      canNavigateToStep,
      updateFindPolicyData,
      updateSelectPetData,
      updateExistingClaimsData,
      updateClaimTypeData,
      updateClaimDetailsData,
      updateReviewData,
      goToNextStep,
      goToPreviousStep,
      navigateToStep,
      isStepCompleted,
      reset,
      resetAfterCurrentStep,
      redirectToCurrentStep,
    }
  },
  {
    persist: true,
  }
)

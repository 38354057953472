import { defineStore } from 'pinia'

import { VetPractice } from '@/domain/pio/VetPractice'
import { Company } from '@/domain/vetPortal/Company'
import { getCompanyByVetPracticeListId } from '@/services/companiesService'
import { getVetPractice } from '@/services/pio/vetPracticeService'

interface AuthStore {
  currentPractice: Company | null
  currentVetPractice: VetPractice | null
  mainVetPractice: VetPractice | null
  practices: string[]
}

export const useAuth = defineStore('auth', {
  state: () =>
    ({
      currentPractice: null,
      currentVetPractice: null,
      mainVetPractice: null,
      practices: [],
    }) as AuthStore,
  actions: {
    async setPractices(practices: string[]) {
      this.practices = practices

      if (
        (!this.currentPractice ||
          (this.currentPractice &&
            !this.practices.includes(
              this.currentPractice.vet_practice_list_id
            ))) &&
        practices?.length > 0
      ) {
        await this.setCurrentPracticeById(practices[0])
        await this.setVetPracticeById(practices[0])
      }
    },
    async setCurrentPracticeById(id: string) {
      this.currentPractice = (await getCompanyByVetPracticeListId(
        id
      )) as Company
    },
    async setVetPracticeById(id: string) {
      this.currentVetPractice = await getVetPractice(id)
      if (!this.currentVetPractice.main_branch_id) {
        this.mainVetPractice = this.currentVetPractice
      } else {
        const mainPractice = await getVetPractice(
          this.currentVetPractice.main_branch_id
        )

        // @ts-expect-error This is necessary because the API.get method does returns API errors in the response. A separate refactor of how practice fetching is needed to resolve
        if (!mainPractice.status) {
          this.mainVetPractice = mainPractice
        } else {
          this.mainVetPractice = this.currentVetPractice
        }
      }
    },
  },
  getters: {
    currentPracticeId(state) {
      return state.currentPractice?.vet_practice_list_id
    },

    mainPracticeId(state) {
      return state.mainVetPractice?.id
    },
  },
  persist: true,
})

<template>
  <div
    class="switch-practice"
    :class="{
      'switch-practice--block': block,
    }"
  >
    <SearchCompanies
      v-model="currentPractice"
      :practice-ids="practices"
      hide-value-whilst-loading
      @option:selected="handleSwitchPractice"
    />
  </div>
</template>

<script lang="ts">
import { mapWritableState, mapState, mapActions } from 'pinia'
import { defineComponent } from 'vue'
import { useRouter, useRoute } from 'vue-router'

import SearchCompanies from '@/components/controls/SearchCompanies/SearchCompanies.vue'
import { useAuth } from '@/store/auth'

export default defineComponent({
  name: 'SwitchPractice',
  components: {
    SearchCompanies,
  },
  props: {
    block: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    return {
      router: useRouter(),
      route: useRoute(),
    }
  },
  computed: {
    ...mapState(useAuth, ['practices']),
    ...mapWritableState(useAuth, ['currentPractice']),
  },
  methods: {
    ...mapActions(useAuth, ['setVetPracticeById']),
    async handleSwitchPractice() {
      if (this.currentPractice) {
        await this.setVetPracticeById(this.currentPractice.vet_practice_list_id)
      }
      this.router.push('/')
    },
  },
})
</script>

<style lang="scss" scoped>
.switch-practice {
  width: 300px;

  &--block {
    width: 100%;
  }
}
</style>

import { useFeatureFlags } from '@/store/featureFlags'

export const isBetterPolicyFetching = () => {
  const featureFlagsStore = useFeatureFlags()
  return featureFlagsStore.getFeatureFlagByName('better-policy-fetching')
}

export const isMainBranchStructure = () => {
  const featureFlagsStore = useFeatureFlags()
  return featureFlagsStore.getFeatureFlagByName('main_branch_structure')
}

export const isVetDataManagement = () => {
  const featureFlagsStore = useFeatureFlags()
  return featureFlagsStore.getFeatureFlagByName('vet_data_management')
}

export const isVetFeesBalance = () => {
  const featureFlagsStore = useFeatureFlags()
  return featureFlagsStore.getFeatureFlagByName('vet_fees_balance_cta')
}

export const isVersionNotificationEnabled = () => {
  const featureFlagsStore = useFeatureFlags()
  return featureFlagsStore.getFeatureFlagByName('version-notification')
}

export const isSaveForLaterEnabled = () => {
  const featureFlagsStore = useFeatureFlags()
  return featureFlagsStore.getFeatureFlagByName('show-save-for-later')
}

export const isInnerLimitsEnabled = () => {
  const featureFlagsStore = useFeatureFlags()
  return featureFlagsStore.getFeatureFlagByName('inner-limits-form-inputs')
}

export const isCheckUserAuthEnabled = () => {
  const featureFlagsStore = useFeatureFlags()
  return featureFlagsStore.getFeatureFlagByName('check-user-auth')
}

export const isInnerLimitsBreakdownEnabled = () => {
  const featureFlagsStore = useFeatureFlags()
  return featureFlagsStore.getFeatureFlagByName(
    'inner-limits-summary-breakdown'
  )
}

export const isVetUserMigrationEnabled = () => {
  const featureFlagsStore = useFeatureFlags()
  return featureFlagsStore.getFeatureFlagByName('vet-user-migration')
}

export const isFnolFormRefactorEnabled = () => {
  const featureFlagsStore = useFeatureFlags()
  return featureFlagsStore.getFeatureFlagByName('fnol-form-refactor')
}

export const isUncoveredSubcoveragesEnabled = () => {
  const featureFlagsStore = useFeatureFlags()
  return featureFlagsStore.getFeatureFlagByName('not-covered-items')
}

export default {
  isBetterPolicyFetching,
  isMainBranchStructure,
  isVetDataManagement,
  isVetFeesBalance,
  isVersionNotificationEnabled,
  isSaveForLaterEnabled,
  isInnerLimitsEnabled,
  isCheckUserAuthEnabled,
  isInnerLimitsBreakdownEnabled,
  isVetUserMigrationEnabled,
  isFnolFormRefactorEnabled,
  isUncoveredSubcoveragesEnabled,
}

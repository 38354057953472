export const policyNumberRegex = (value: string) => {
  const ibaRegex = /^1031268000/
  const policyNum = value.replace(/-/g, '').toLowerCase()

  if (
    policyNum.length < 6 ||
    policyNum.length === 8 ||
    (ibaRegex.test(policyNum) && policyNum.length < 16 && policyNum.length > 9)
  ) {
    return 'This looks too short.'
  }

  if (
    policyNum.length === 7 ||
    (policyNum.length > 9 && !ibaRegex.test(policyNum)) ||
    policyNum.length > 23
  ) {
    return 'This looks too long.'
  }

  if (policyNum.length >= 9 && /[a-z]/.test(policyNum)) {
    return 'A policy number this length should only contain numbers.'
  }

  if (/[^a-z^0-9^-]/.test(policyNum)) {
    return 'Policy numbers can only contain letters, numbers, or hyphens.'
  }

  if (policyNum.length === 9 && !/^000/.test(policyNum)) {
    return 'A 9 digit policy number should start with 000.'
  }

  // Final regex get out clause
  const policyNumberRegex =
    /^(1031268000\d{6}|1031268000\d{6}-\d{6,}|000\d{6}|000-\d{3}-\d{3}|\d{6}|[a-zA-Z0-9]{6})$/
  return policyNumberRegex.test(value)
    ? true
    : 'This policy number does not look valid.'
}

export const caseRegex = (value: string) => {
  const caseIdRegex = /^cas_\w{22}$/
  const caseRefRegex = /^cas_\w{6}$/

  if (value.length > 10) {
    return caseIdRegex.test(value) ? true : 'This case ID does not look valid'
  }

  return caseRefRegex.test(value) ? true : 'This case ref does not look valid'
}

export const lossRegex = (value: string) => {
  const lossIdRegex = /^los_\w{22}$/
  const lossRefRegex = /^los_\w{6}$/

  if (value.length > 10) {
    return lossIdRegex.test(value) ? true : 'This loss ID does not look valid'
  }

  return lossRefRegex.test(value) ? true : 'This loss ref does not look valid'
}

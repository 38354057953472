export const isRejected = (
  input: PromiseSettledResult<unknown>
): input is PromiseRejectedResult => input.status === 'rejected'

export const isFulfilled = <T>(
  input: PromiseSettledResult<T>
): input is PromiseFulfilledResult<T> => {
  return input.status === 'fulfilled' && !!input.value
}

export function mapS3UriToUrl(s3Uri: string): string {
  return (
    s3Uri
      .replace(/^s3:/, 'https:')
      // replaces first '/' surrounded by alphanumeric characters with .s3.amazonaws.com/ using lookahead
      .replace(/(\w)(\/)(?=\w)/, '$1.s3.amazonaws.com/')
  )
}
export function mapUrlToS3Uri(url: string): string {
  return url
    .replace(/^https:/, 's3:')
    .replace('.s3.amazonaws.com', '')
    .replace('claim-docs.eu.policies.io', 'manypets-claims-docs-eu-prod')
}

export function downloadCSVBlob(fileContents: string, filename: string) {
  const blob = new Blob([fileContents], {
    type: 'text/csv;charset=utf-8',
  })

  // Create an object URL for the blob object
  const url = URL.createObjectURL(blob)

  const a = document.createElement('a')

  a.href = url
  a.download = `${filename}.csv`

  const clickHandler = () => {
    setTimeout(() => {
      URL.revokeObjectURL(url)
      removeEventListener('click', clickHandler)
    }, 150)
  }

  a.addEventListener('click', clickHandler, false)

  // Programatically clicks the download link
  a.click()

  return a
}
